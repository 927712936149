import media from '../../styles/media'

import styled from 'styled-components'

const BgPatern = require('../../assets/images/general/patern.jpg')

export const StyledContainer = styled.div`
  background-image: url(${BgPatern});
  background-size: auto;
  background-position: 0 center;
  padding-top: 10rem;
  padding-bottom: 30px;
  position: relative;
  overflow-y: hidden;
  ${media.xs`
    padding-top: 4rem;
    background-position: center 0%;
  `};
  ${media.md`
    padding-top: 6rem;
    background-position: center 0%;
  `};
`

export const StyledRow = styled.div`
  align-items: center;
  justify-content: center;
`

export const StyledCol = styled.div`
  text-align: center;
  background-size: 100%;
  ${media.lg`
    background-position: center 0%;
  `};
`

export const IngredientsImgMobile = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: block;
  ${media.xs`
    display: none;
  `};
`

export const IngredientsImg = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: none;
  ${media.xs`
    display: block;
  `};
`
