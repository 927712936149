import React from 'react'

import {
  StyledRow,
  StyledContainer,
  StyledCol,
  IngredientsImg,
  IngredientsImgMobile,
} from './style'
import BenefitItem from './components/BenefitItem'
import Button from '../Button'
import AuthContext from '../../context/auth-context'

const PercentImg = require('../../assets/images/pages/icons/percent.svg')
const BellImg = require('../../assets/images/pages/icons/bell.svg')
const DrinkImg = require('../../assets/images/pages/icons/drink.svg')
const DeliveryImg = require('../../assets/images/pages/icons/delivery_moto.svg')
const MenuImg = require('../../assets/images/pages/icons/menu.svg')
const BgBenefits = require('../../assets/images/pages/bg-benefits.jpg')
const BgBenefitsMobile = require('../../assets/images/pages/bg-benefits-mobile.jpg')

const BenefitsSection = () => (
  <StyledContainer className="container-fluid" id="benefits-sec">
    <IngredientsImg src={BgBenefits} />
    <IngredientsImgMobile src={BgBenefitsMobile} />
    <StyledRow className="row">
      <div className="container">
        <StyledRow className="row">
          <BenefitItem
            icon={PercentImg}
            text="Todos los días podrás disfrutar entre el 10% y 20% de descuento de toda tu cuenta."
            fit={true}
          />
          <BenefitItem
            icon={DrinkImg}
            text="Acceso especial a los eventos gastronómicos más exclusivos de la ciudad."
          />
          <BenefitItem
            icon={BellImg}
            text="Tan solo mencionando que eres miembro del club Grande Table, tienes preferencia para hacer reservaciones de mesas y trato privilegiado en el restaurante."
          />
          <BenefitItem
            icon={DeliveryImg}
            text="Los mejores restaurantes de la ciudad a domicilio, con Grande Table Delivery. Disfruta de delivery gratuito y descuentos especiales para socios."
          />
          <BenefitItem
            icon={MenuImg}
            text="Accede a descubrir nuevos sabores, degustar catas de vino, cocinar junto a tu chef favorito y muchas experiencias más."
          />
        </StyledRow>
      </div>
    </StyledRow>
    <AuthContext.Consumer>
      {(context: any) => {
        return context.user ? (
          ''
        ) : (
          <div className="row">
            <StyledCol className="col-md-12">
              <Button
                style={{ marginTop: '30px' }}
                link="membership"
                text="Quiero ser parte del club"
                id="GTABLE_JOINCLUB"
              />
            </StyledCol>
          </div>
        )
      }}
    </AuthContext.Consumer>
  </StyledContainer>
)
export default BenefitsSection
