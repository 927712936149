import Theme from '../../styles/theme'
import media from '../../styles/media'

import styled, { keyframes } from 'styled-components'

const BgArrow = require('../../assets/images/general/benefits-more.png')

interface imgProps {
  orderElement: number
}

export const BenefitsContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  ${media.md`
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  `};
`

const leaveRight = keyframes`
  0% {
    transform: translateX(0);
    opacity: 0;
  }

25% {
    transform: translateX(0);
    opacity: 1;
  }
  50% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
`

const leaveLeft = keyframes`
  0% {
    transform: translateX(0);
    opacity: 0;
  }

25% {
    transform: translateX(0);
    opacity: 1;
  }

  50% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
`

const enter = keyframes`
  to {
    opacity: 0;
  }
  from {
    opacity: 1;
  }
`

export const ImageContainer = styled.div`
  display: none;
  order: ${(props: imgProps) => (props.orderElement ? props.orderElement : 0)};
  ${media.md`  
    display: block;
    margin: 0px 10px;
    width: 150px;
    img {
      filter: invert(40%);
      max-width: 110px;
    }
  `};

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    opacity: 0;
    animation: ${leaveLeft} 6s ease-in-out infinite;
  }

  &:nth-child(4),
  &:nth-child(5),
  &:nth-child(6) {
    opacity: 0;
    animation: ${leaveRight} 6s ease-in-out infinite;
  }
`
export const StyledBenefit = styled.h2`
  color: ${Theme.secondaryColor};
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  font-family: 'DroidSerif';
  font-size: 30px;
  order: 2;
  margin-bottom: 0;
`

export const ArrowContainer = styled.div`
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem 4rem 1rem 4rem;
  background-image: url(${BgArrow});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  display: none;
  z-index: 99;
  ${media.md`  
    display: block;
  `};
`

export const StyledRow = styled.div`
  position: relative;
`

export const StyledArrow = styled.img`
  max-width: 70%;
  cursor: pointer;
`
