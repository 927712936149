import media from '../../styles/media'
import styled from 'styled-components'

interface RestaurantContainerProps {
  onlyImage?: boolean
}

export const StyledRow = styled.div`
  background: #242424;
  .centered {
    justify-content: center;
  }
  width: 100%;
`

export const RestaurantContainer = styled.div<RestaurantContainerProps>`
  padding: 1rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${media.md`
    margin-top: 11px;
  `}
`

export const StyledImage = styled.img`
  margin-top: 11px;
  max-width: 100%;
  margin: auto;
  ${media.md`  
    margin:auto;
    margin-bottom: 0;
    margin-top: 8px;
  `};
`

export const StyledText = styled.p`
  color: white;
  flex: 2;
  margin-bottom: 1rem;
  align-items: center;
  display: inline-block;
  text-align: center;
  padding: 0rem 1rem;
  ${media.md`  
    border-left: solid 1px;
    margin: 0rem 2rem;
    line-height: 1.38;
    letter-spacing: 2.1px;
  `};
`
