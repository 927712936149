import React from 'react'
import animateScrollTo from 'animated-scroll-to'

import {
  BenefitsContainer,
  ImageContainer,
  StyledBenefit,
  ArrowContainer,
  StyledRow,
  StyledArrow
} from './style'
import { RequestGetter } from '../../helpers/request'
import LazyImage from '../LazyImage'

const Arrow = require('../../assets/images/general/benefits-arrow.png')

interface Logo {
  icon: string
  slug: string
}
class BenefitsBar extends React.Component {
  state = {
    logos: [],
    intervalId: 0
  }

  componentDidMount() {
    this.getLogos()
    var intervalId = setInterval(() => this.getLogos(), 6000)
    this.setState({ intervalId: intervalId })
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId)
  }

  handleScroll = () => {
    if (document.querySelector('#benefits-sec')) {
      animateScrollTo(document.querySelector('#benefits-sec') as HTMLElement)
    }
  }

  getLogos = () => {
    const { logos } = this.state
    let url = 'logos?'
    logos.forEach((logo: Logo) => {
      url = url.concat(`current[]=${logo.slug}&`)
    })
    RequestGetter('get', url).then((data: any) => {
      this.setState({
        logos: data.data
      })
    })
  }

  render() {
    const { logos } = this.state
    return (
      <StyledRow className="row">
        <div className="container-fluid">
          <div className="row">
            <BenefitsContainer className="col-md-12">
              {logos &&
                logos.map((logo: Logo, index) => (
                  <ImageContainer key={`image-${index}`} orderElement={index}>
                    <LazyImage src={logo.icon} />
                  </ImageContainer>
                ))}
              <StyledBenefit>Beneficios</StyledBenefit>
            </BenefitsContainer>
          </div>
        </div>
        <ArrowContainer>
          <StyledArrow src={Arrow} alt="" onClick={this.handleScroll} />
        </ArrowContainer>
      </StyledRow>
    )
  }
}

export default BenefitsBar
