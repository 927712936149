import React from 'react'
import {
  StyledItem,
  IconContainer,
  StyledContent,
  Text,
  StyledImage,
  StyledBgImg,
  StyledBgImgRectangle
} from './style'

const Rectangle = require('../../../../assets/images/general/rectangle.svg')
const Hexagon = require('../../../../assets/images/general/hexagon.svg')
interface Props {
  icon: string
  text: string
  alternText?: string
  fit?: boolean
}
const BenefitItem = ({ icon, text, alternText, fit }: Props) => {
  return (
    <StyledItem className="col-12 col-lg-3 col-md-4 col-xl">
      <IconContainer>
        <StyledBgImg src={Hexagon} alt="" />
        <StyledImage src={icon} alt="" fit={fit} />
      </IconContainer>
      <StyledContent>
        <StyledBgImgRectangle src={Rectangle} alt="" />
        <Text>
          <p>{text}</p>
          <p>{alternText}</p>
        </Text>
      </StyledContent>
    </StyledItem>
  )
}

export default BenefitItem
