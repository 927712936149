import styled from 'styled-components'
import theme from '../../../styles/theme'

interface SliderArrowProps {
  background?: string
  color?: string
}

export const SliderArrow = styled.button`
  z-index: 1;
  background: ${(props: SliderArrowProps) =>
    props.background ? props.background : '#ffffff !important'};
  width: 50px !important;
  height: 50px !important;
  text-align: center !important;

  i {
    color: ${(props: SliderArrowProps) => (props.color ? props.color : theme.purpleBrown)};
    font-size: 20px !important;
    display: inline-block;
    top: 30% !important;
    position: absolute;
    left: 25%;
  }

  &.slick-prev:before,
  &.slick-next:before {
    content: '' !important;
  }
`
