import React, { Component } from 'react'
import moment from 'moment'
import axios from 'axios'
import { SliderContainer, StyledContainer, ItemContainer, TitleContainer, LogoImage, DetailContainer, SpecialityName, Shadow } from './style'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { RequestGetter } from '../../../../helpers/request'
import { navigate } from 'gatsby'
import '../../../../assets/fontello/css/gt-font.css'
import { Arrow } from '../../../Generics/SliderArrow'

interface RestaurantSliderState {
  items: any[]
  loading: boolean
  callback?: Function
  type: string
}

class RestaurantSlider extends Component<{ callback?: any; type: string; onChangeEvent?: any; noId?: any; eventId?: number }, any> {
  state: RestaurantSliderState = {
    items: [],
    loading: true,
    type: this.props.type,
  }

  componentDidMount() {
    if (this.state.type == 'events') {
      this.getEvents()
      return
    }
    this.getRestaurats()
  }

  getRestaurats = () => {
    const { noId } = this.props
    const url = noId ? `restaurants?noId=${noId}` : 'restaurants?recommended=1'
    RequestGetter('get', url).then((data: any) => {
      this.setState({ items: data.data, loading: false })
    })
  }

  getEvents = () => {
    axios.post(`${process.env.API_URL}events`, { eventTypes: [], startTime: moment().format('YYYY-MM-DD') }, {}).then((response: any) => {
      let events = response.data.data
      events = events.filter((event: any) => event.id != this.props.eventId)
      this.setState({ items: events, loading: false })
    })
  }

  handleClick = (type: string, sliderItem: any) => {
    const { onChangeEvent } = this.props
    let excludedItem = ''
    if (type === 'events') {
      navigate(`/events/${sliderItem.id}`)
      excludedItem = sliderItem.id
    } else {
      navigate(`/restaurants/${sliderItem.slug}`)
      excludedItem = sliderItem.slug
    }
    onChangeEvent?.(excludedItem)
  }

  imageSlider = () => {
    const { items, type } = this.state
    return items.map((sliderItem: any) => {
      let specility = ''

      if (sliderItem.hasOwnProperty('speciality')) {
        if (typeof sliderItem.speciality !== 'undefined') {
          specility = sliderItem.speciality.name
        }
      }

      return (
        <ItemContainer key={sliderItem.id}>
          <StyledContainer
            style={{ width: '270px', margin: 'auto' }}
            className="slidercontainer"
            onClick={() => this.handleClick(this.props.type, sliderItem)}
            image={sliderItem.bg_image}
          >
            <Shadow />
            <DetailContainer>
              {type == 'events' ? <SpecialityName>{sliderItem.title}</SpecialityName> : <LogoImage src={sliderItem.icon} />}
              {specility && <SpecialityName>{specility}</SpecialityName>}
            </DetailContainer>
          </StyledContainer>
        </ItemContainer>
      )
    })
  }

  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 1000,
      autoplay: true,
      autoplaySpeed: 2500,
      slidesToShow: 4,
      slidesToScroll: 1,
      pauseOnHover: true,
      arrows: true,
      nextArrow: <Arrow />,
      prevArrow: <Arrow isPrev />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 0,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }
    const { items, type } = this.state

    let content

    if (!items) {
      content = ''
    } else if (items.length <= 4) {
      content = <div className="row static"> {this.imageSlider()}</div>
    } else {
      content = (
        <div className="col-sm-12">
          <Slider {...settings}>{this.imageSlider()}</Slider>
        </div>
      )
    }

    return (
      <SliderContainer className={!items || items.length == 0 ? 'hide-on-empty' : ''}>
        <div className="container">
          <div className="row">
            <TitleContainer className="col-sm-12">
              <strong>{type == 'events' ? 'Eventos' : 'Nuestros'}</strong> <span>Recomendados</span>
            </TitleContainer>
          </div>
          <div className="row">{content}</div>
        </div>
      </SliderContainer>
    )
  }
}

export default RestaurantSlider
