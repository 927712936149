import React from 'react'
import { StyledImage, StyledLoading, StyledContainer } from './style'

const loadingGif = require('../../assets/images/general/loading-img.gif')

interface PropsLazyImage {
  src: string
  alt?: string
}

class LazyImage extends React.Component<PropsLazyImage> {
  state = { imageStatus: 'loading', intervalId: 0 }

  componentDidMount() {
    const intervalId = setInterval(() => this.checkImage(), 500)
    this.setState({ intervalId })
  }

  checkImage = () => {
    if (this.props.src && this.state.imageStatus != 'loaded') {
      this.handleImageLoaded()
    }
  }

  handleImageLoaded = () =>
    this.setState({ imageStatus: 'loaded' }, () => clearInterval(this.state.intervalId))

  handleImageErrored = () => this.setState({ imageStatus: 'failed' })

  render() {
    const { alt, src } = this.props
    const { imageStatus } = this.state
    return (
      <StyledContainer className={`status-${imageStatus}`}>
        <StyledImage
          status={imageStatus}
          src={src}
          onLoad={this.handleImageLoaded}
          onError={this.handleImageErrored}
          alt={alt}
        />
        <StyledLoading status={imageStatus} src={loadingGif} alt="Loading image" />
      </StyledContainer>
    )
  }
}

export default LazyImage
