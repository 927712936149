import axios, { Method } from 'axios'

export const RequestGetter = (
  method: Method,
  urlConsume: string,
  data: object | null = {},
  params?: any
) => {
  return axios({
    method: method,
    url: `${process.env.API_URL}/${urlConsume}`,
    data: data,
    params
  })
}
