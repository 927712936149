import styled from 'styled-components'
import media from '../../../../styles/media'

export const StyledItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 1rem;
  margin-bottom: 10px;
  ${media.md`  
    margin-bottom: 30px;
    align-self: stretch;
    flex-direction: column;
  `};
`
export const StyledContent = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  flex: 65%;
  margin-left: 1rem;
  position: relative;
  ${media.sm`  
    padding: 0;
    width: 100%;
    max-width: 100%;
    flex: auto;
    margin: auto;
  `};
`
export const IconContainer = styled.div`
  text-align: center;
  position: relative;
  padding: 2rem 1rem;
  display: flex;
  margin: auto;
  flex: auto;
  ${media.sm`  
    width: 100%;
    padding: 2rem;
    padding: 2.5rem 1rem;
    top: 20px;
    margin: auto;
  `};
`
export const Text = styled.div`
  font-weight: 300;
  ${media.sm`
    position: absolute;
    width: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  `};
`

interface IconProps {
  fit?: boolean
}

export const StyledImage = styled.img`
  max-width: 100%;
  margin: auto;
  ${(props: IconProps) => {
    if (props.fit === true) {
      return `max-height: 45px;
              width: 50px;`
    }
  }};
`

export const StyledBgImg = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
`

export const StyledBgImgRectangle = styled.img`
  display: none;
  ${media.sm`
    display: block;
    height: 100%;
    width: 100%;
  `};
`
