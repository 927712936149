import styled from 'styled-components'
import media from '../../../../styles/media'

export const SliderContainer = styled.div`
  background-color: #000000;
  margin: 0;
  width: 100%;
  padding: 40px 0;
  .slick-next {
    right: -15px;
  }
  .slick-prev {
    left: -15px;
  }

  &.hide-on-empty {
    display: none;
  }

  ${media.sm`  
  .slick-next {
    right: -25px;
  }
  .slick-prev {
    left: -25px;
  }
  `};
  .static {
    width: 100%;
    justify-content: center;
  }
`

type Props = {
  image?: string
}

export const StyledContainer = styled.div`
  background-image: url(${(props: Props) => (props.image ? props.image : '')});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 0;
  cursor: pointer;
  height: 190px;
  position: relative;
`

export const ItemContainer = styled.div`
  padding: 10px;
`

export const TitleContainer = styled.div`
  text-transform: uppercase;
  width: 100%;
  font-family: 'Roboto';
  padding-bottom: 30px;
  letter-spacing: 1.08px;
  line-height: normal;
  color: #ffffff;
  font-style: normal;
  font-stretch: normal;
  font-size: 14px;
  strong {
    width: 81px;
    height: 16px;
    font-weight: bold;
  }
  span {
    width: 124px;
    height: 19px;
    font-weight: 300;
  }
`

export const LogoImage = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: fit-content;
`
export const DetailContainer = styled.div`
  position: relative;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 1;
  height: 100%;
  display: flex;
  align-items: center;
`
export const RestaurantName = styled.div`
  padding-top: 1rem;
  font-size: 20px;
  text-transform: capitalize;
  font-family: DroidSerif;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.85;
  letter-spacing: normal;
  color: #ffffff;
`
export const SpecialityName = styled.div`
  padding-top: 0.5rem;
  width: 100%;
  height: 19px;
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.08px;
  text-align: center;
  color: #ffffff;
`
export const Shadow = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
`
