import Axios from 'axios'
import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import { Arrow } from '../Generics/SliderArrow'
import { Banner } from './banner'
import {
  BannerItem,
  Container,
  LinkContainer,
  SliderGroup,
  StyledContent,
  StyledText,
  StyledTitle,
} from './style'

const defaultBanner = require('../../assets/images/pages/bg-home.jpg')

const customStylesLoading = {
  content: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: 101,
    padding: '0 0 10rem 0',
    backgroundColor: 'transparent',
    border: 'none',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
  },
}

const DefaultItem = () => (
  <BannerItem className="row" background={defaultBanner}>
    <StyledContent className="container">
      <div className="dummy-and-empty"></div>
      <Subjects
        index={1}
        title="Sé parte del Club Gourmet más exclusivo del país."
        subTitle="Los mejores restaurantes de la ciudad y sus eventos gastronómicos más exclusivos están  a tu alcance."
        buttonText="Obtén tu membresía"
        buttonLink="membership"
        gtm="CLICKBENEFICIOS_HOME"
      />
    </StyledContent>
  </BannerItem>
)

const CurrentBanners = (props: any) => {
  const { banner, index } = props
  return (
    <Container>
      <BannerItem className="hidden-xs" background={banner.banner_url}>
        <StyledContent className="container">
          <Subjects
            index={index}
            title={banner.title}
            subTitle={banner.sub_title}
            buttonText={banner.button_text}
            buttonLink={banner.button_link}
            externalLink={banner.button_external_link}
            gtm={banner.button_gtm}
          />
        </StyledContent>
      </BannerItem>
      <BannerItem className="visible-xs" background={banner.banner_url_mobile}>
        <StyledContent className="container">
          <Subjects
            index={index}
            title={banner.title}
            subTitle={banner.sub_title}
            buttonText={banner.button_text}
            buttonLink={banner.button_link}
            externalLink={banner.button_external_link}
            gtm={banner.button_gtm}
          />
        </StyledContent>
      </BannerItem>
    </Container>
  )
}

const Subjects = (props: any) => {
  const { title, subTitle, externalLink, gtm, index, buttonText, buttonLink } = props
  return (
    <div className="subjects">
      <StyledTitle>{title}</StyledTitle>
      <StyledText>{subTitle}</StyledText>
      <LinkContainer>
        {externalLink ? (
          <a className="link" href={externalLink} target="_blank" id={gtm || `banner_btn_${index}`}>
            {buttonText}
          </a>
        ) : (
          <Link className="link" to={buttonLink} id={gtm || `banner_btn_${index}`}>
            {buttonText}
          </Link>
        )}
      </LinkContainer>
    </div>
  )
}

const Banners = () => {
  const [banners, setBanners] = useState([])
  
  useEffect(() => {
    if (banners.length == 0) {
      getBanners()
    }
  })

  const getBanners = async () => {
    const response = await Axios.get(`${process.env.API_URL}banners/all`).catch(() => {
      console.log('Error al obtener banners')
      return null})
    const data = response ? await response.data : []
    setBanners(data)
  }

  const setting = {
    infinite: true,
    fade: true,
    speed: 900,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToScroll: 1,
    pauseOnHover: true,
    arrows: true,
    nextArrow: <Arrow background="transparent" color="#ffffff" />,
    prevArrow: <Arrow isPrev background="transparent" color="#ffffff" />,
    dots: true,
  }

  return (
    <SliderGroup>
      {banners.length == 0 ? (
        <DefaultItem />
      ) : (
        <Slider className="row" {...setting}>
          {banners.map((banner: Banner, index: number) => (
            <CurrentBanners banner={banner} index={index} key={banner.id} />
          ))}
        </Slider>
      )}
    </SliderGroup>
  )
}

export default Banners
