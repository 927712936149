import React from 'react'
import { SliderArrow } from './style'

interface ArrowProps {
  className?: string,
  onClick?: any,
  isPrev?: boolean,
  background?: string,
  color?: string
}

export const Arrow = (props: ArrowProps) => {
  const { className, onClick, isPrev, background, color } = props
  return (
    <SliderArrow
      className={className}
      onClick={onClick}
      background={background}
      color={color}
    >
      <i
        className={isPrev ? 'icon-grande-table-13' : 'icon-grande-table-14'}
        style={{ fontSize: '16px' }}
      />
    </SliderArrow>
  )
}
