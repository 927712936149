import styled from 'styled-components'

interface ImageProps {
  status: string
}

export const StyledImage = styled.img`
  display: ${(props: ImageProps) => (props.status === 'loaded' ? 'flex' : 'none')};
  margin: auto;
  max-width: 100%;
`
export const StyledLoading = styled.img`
  display: ${(props: ImageProps) => (props.status !== 'loaded' ? 'flex' : 'none')};
  margin: auto;
  max-width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
`

export const StyledContainer = styled.div``
