import styled, {css} from 'styled-components'
import media from '../../styles/media'
import theme from '../../styles/theme'

interface BannerProps {
  background?: string
}

export const SliderGroup = styled.div`
  .slick-slider {
    .slick-list {
      margin: 0;
    }
    .slick-dots {
      bottom: 0;
      padding-bottom: 10px;
      ${media.sm`
      padding-bottom: 20px;
      `}
      ${media.lg`
      padding-bottom: 30px;
      `}
      li {
        button {
          &:before {
            color: rgba(255, 255, 255, 0.7);
            font-size: 14px;
            ${media.md`
            opacity: 1; 
            `}
          }
        }
        &.slick-active {
          button {
            &:before {
              color: ${theme.secondaryColor};
              font-size: 18px;
            }
          }
        }
      }
    }

    .slick-prev {
      left: 0;
      ${media.sm`
      left: 20px;
      `}
      ${media.md`
      left: 50px;
      `}
      ${media.lg`
      left: 80px;
      `}
    }
    .slick-next {
      right: 0;
      ${media.sm`
      right:20px;
      `}
      ${media.md`
      right: 50px;
      `}
      ${media.lg`
      right: 80px;
      `}
    }
  }
`

export const GrandeTableLogoGroup = styled.div`
  position: absolute;
  text-align: center;
  z-index: 1;
  top: 85px;
  left: 0;
  right: 0;
  margin: 0 auto;
  ${media.md`
  top: 120px;
  `}

  .dummy-and-empty {
    display: inline-block;
    ${media.md`
    width: 50%;
    `}
  }

  .logo-wrapper {
    display: inline-block;
    width: 100%;
    ${media.md`
    width: 50%;
    `}

    .gt-logo-cropper {
      margin: 0 auto;
      width: 155px;
      ${media.sm`
      width: 175px;
      padding-top: 20px;
      `}
      ${media.lg`
      width: 193px;
      `}
    }

    .delimiter {
      margin: 28px auto 0;
      width: 75%;
      ${media.sm`
      width: 50%;
      `}
      ${media.md`
      width: 75%;
      `}
      ${media.lg`
      width: 85%;
      `}
    }
  }
`

export const GrandeTableLogo = styled.img`
  width: 100%;
  margin: auto;
  ${media.md`
  width: 80%;
  `}
  ${media.lg`
  width: 100%;
  `}
`
export const Container = styled.div`
  .visible-xs{
    display: none;
    @media(max-width: 992px){
      display: flex;
      }
  }
  .hidden-xs{
    display: visible;
    @media(max-width: 992px){
      display: none;
      }
   
  }
`
export const BannerItem = styled.div`
  display: flex;
  min-height: 480px;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7)),
    url(${(props: BannerProps) => props.background});
  background-repeat: no-repeat;

  @media(max-width: 992px){
    background-size: cover;
  }

    ${media.md`
    ${(props: BannerProps) => `background-image: url(${props.background});`};
    background-size: cover;
    min-height: 440px;
  `}
  
  ${media.lg`
    min-height: 480px;
  `}
`

export const StyledContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;

  .subjects {
    display: flex;
    width: 80%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  ${media.md`
    justify-content: flex-end;
    .subjects {
      width: 35%;
      margin-right: 5%;
    }
  `};
`

export const StyledTitle = styled.h3`
  color: white;
  display: flex;
  font-size: 24px;
  line-height: 26px;
  margin-bottom: 15px;
  letter-spacing: normal;
  font-family: 'SFProText Bold';
  justify-content: center;
  flex-direction: column;

  ${media.md`
    margin-bottom: 25px;
    font-size: 30px;
    line-height: 33px;
  `}
  ${media.lg`
    font-size: 36px;
    line-height: 40px;
  `}
`

export const StyledText = styled.p`
  display: flex;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 25px;
  letter-spacing: 0.92px;
  font-family: 'SFProText';
  justify-content: center;
  flex-direction: column;

  ${media.md`
    flex-flow: inherit;
  `}
`

export const LinkContainer = styled.div`
  font-family: 'SFProText';
  font-size: 13px;
  line-height: 15px;

  .link {
    display: inherit;
    text-transform: uppercase;
    letter-spacing: 0.75px;
    transition: all 0.3s ease-in-out;
    border: 2px solid ${theme.secondaryColor};
    border-radius: 17.5px;
    background-color: ${theme.secondaryColor};
    padding: 10px 40px;
    color: #ffffff;
  }
  &:hover {
    a {
      color: ${theme.secondaryColor};
      background-color: #ffffff;
    }
  }
`
