import React from 'react'
import Button from '../Button'
import { ButtonContainer } from '../Button/style'
import { StyledRow, RestaurantContainer, StyledImage, StyledText } from './style'

const RestaurantLogo = require('../../assets/images/general/restaurants.svg')

interface RestaurantBarProps {
  onlyImage?: boolean
}

const RestaurantsBar = (props: RestaurantBarProps) => (
  <StyledRow>
    <div className="container-fluid">
      <div className="row">
        <RestaurantContainer className="container centered" onlyImage={props.onlyImage}>
          <StyledImage src={RestaurantLogo} />
          {props.onlyImage !== true && (
            <StyledText>
              Sorpréndete con una mezcla inigualable de mágicos sabores, escoge el restaurante al
              que deseas visitar y aprovecha los beneficios exclusivos que Grande Table tiene para
              ti.
            </StyledText>
          )}
          {props.onlyImage !== true && (
            <ButtonContainer>
              <Button
                link="restaurants"
                text="Ver Todos"
                type="secondary"
                id="GTABLE_VERTODOS"
              />
            </ButtonContainer>
          )}
        </RestaurantContainer>
      </div>
    </div>
  </StyledRow>
)

export default RestaurantsBar
