import React from 'react'
import { Container, StyledContainer } from '../components/StylesPages/indexStyle'
import { navigate } from 'gatsby'
import BenefitsBar from '../components/BenefitsBar'
import BenefitsSection from '../components/BenefitsSection'
import RestaurantsBar from '../components/RestaurantsBar'
import Layout from '../components/Layout'
import 'react-app-polyfill/node_modules/core-js/es/promise'
import RestaurantSlider from '../components/RestaurantsSection/components/RestaurantSlider'
import Banners from '../components/Banners'
import { HelmetComponent } from '../components/HelmetComponent'

export default () => (
  <Layout currentPage={'home'}>
    <HelmetComponent title="Club Gourmet - Grande Table" description="Sé parte del Club Gourmet más exclusivo del país. " />
    <Container>
      <StyledContainer className="container-fluid">
        <Banners />
        <BenefitsBar />
      </StyledContainer>
      <BenefitsSection />
      <RestaurantsBar />
      <RestaurantSlider
        callback={() => {
          navigate(`/restaurant`)
        }}
        type={'restaurant'}
      />
    </Container>
  </Layout>
)
